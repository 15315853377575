<template>
  <div ref="ukModal" class="uk-flex-top qrPopup" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button class="uk-modal-close-outside" type="button" uk-close></button>
      <img
        class="qrPopup-qr"
        src="@/assets/knowledge_841541d2-9d67-4cca-ad62-3ea780c288ac.jpg"
      />
    </div>
  </div>
</template>

<script>
import qrPop from "./qrPop";
export default {
  created() {
    qrPop.qrNode = this;
  },
};
</script>

<style lang="less">
.qrPopup {
  .uk-modal-dialog {
    width: 286px;
    padding: 0;
  }
  &-qr {
    width: 286px;
    height: 286px;
    display: block;
  }
}
</style>