import UIkit from "uikit"

export default {
     get qr() {
        console.log(this.qrNode.$el)
        if (!this._ukModal) {
            this._ukModal = UIkit.modal(this.qrNode.$el);
        }
        return this._ukModal;
    },
    _ukModal: null,
    qrNode: null,

}