<template>
  <div id="app">
    <header-vue />
    <router-view />
    <footer-vue />
    <copyright-vue />
    <qr-pop />
  </div>
</template>
<script>
import headerVue from "@/components/header.vue";
import footerVue from "@/components/footer.vue";
import copyrightVue from "@/components/copyright.vue";
import qrPop from "@/components/qrPop.vue";
export default {
  components: {
    headerVue,
    footerVue,
    copyrightVue,
    qrPop,
  },
};
</script>

<style>
</style>