<template>
  <div
    class="header"
    :class="{ 'header-fixed': isFixed }"
    uk-sticky="cls-active:header-sticky;"
    @active="isSticky = true"
    @inactive="isSticky = false"
  >
    <div class="uk-navbar-container uk-navbar-transparent">
      <div class="uk-container uk-container-large">
        <nav uk-navbar>
          <div class="uk-navbar-left">
            <router-link to="/" class="uk-navbar-item uk-logo">
              <img
                v-if="isFixed || isSticky"
                class="header-logo"
                src="@/assets/a5ebf868-f2ca-4ef6-b7b5-7d4b4ef662e6.png"
              />
              <img
                v-else
                class="header-logo"
                src="@/assets/f89a463b-5e84-4d5b-8d80-f1ce1aae5c9b.png"
              />
            </router-link>
          </div>
          <div class="uk-navbar-right">
            <ul class="uk-navbar-nav">
              <template v-for="(item, index) in navs">
                <li
                  :class="{ 'uk-active': item.path == activeNav.path }"
                  :key="index"
                >
                  <router-link :to="item.path">{{ item.label }}</router-link>
                </li>
              </template>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        {
          path: "/",
          label: "首页",
          header: {
            isFixed: false,
          },
        },
        {
          path: "/aboutUs",
          label: "关于我们",
          header: {
            isFixed: true,
          },
        },
        // {
        //   path: "/",
        //   label: "产品服务",
        //   header: {
        //     isFixed: true,
        //   },
        // },
        // {
        //   path: "/",
        //   label: "解决方案",
        //   header: {
        //     isFixed: true,
        //   },
        // },
        {
          path: "/successful",
          label: "成功案例",
          header: {
            isFixed: true,
          },
        },
        {
          path: "/contactUs",
          label: "联系我们",
          header: {
            isFixed: true,
          },
        },
      ],
      isSticky: false,
    };
  },
  computed: {
    activeNav() {
      return this.navs.find((item) => {
        return this.$route.path == item.path;
      });
    },
    isFixed() {
      return this.activeNav?.header.isFixed || false;
    },
  },
};
</script>

<style lang="less">
.header {
  &-logo {
    height: 58px;
    display: block;
  }
  .uk-navbar-nav {
    li,
    li:hover {
      > a {
        color: #333;
      }
    }
    li.uk-active {
      > a {
        color: @color;
      }
    }
  }
  &-fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .uk-navbar-nav {
      li,
      li:hover {
        > a {
          color: #fff;
        }
      }
      li.uk-active {
        position: relative;
        ::after {
          content: "";
          height: 5px;
          background-color: #fff;
          position: absolute;
          width: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        > a {
          color: #fff;
        }
      }
    }
  }
  &-sticky {
    background-image: @linear-gradient;
    color: #fff;
    z-index: 999;
    .uk-navbar-nav {
      li,
      li:hover {
        > a {
          color: #fff;
        }
      }
      li.uk-active {
        position: relative;
        ::after {
          content: "";
          height: 5px;
          background-color: #fff;
          position: absolute;
          width: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
        > a {
          color: #fff;
        }
      }
    }
  }
}
</style>