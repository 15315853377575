<template>
  <div class="footer">
    <div class="uk-container uk-container-large">
      <div class="uk-flex uk-flex-between">
        <div>
          <div>
            <div class="footer-label">产品方案</div>
            <div>
              <div class="footer-desc">旅游分销系统</div>
              <div class="footer-desc">私有化部署</div>
              <div class="footer-desc">目的地大运营</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="footer-label">特色产品</div>
            <div>
              <div class="footer-desc">微信小程序</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="footer-label">通用解决方案</div>
            <div>
              <div class="footer-desc">微信生态数字化运营</div>
              <div class="footer-desc">小红书生态数字化运营</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="footer-label">行业解决方案</div>
            <div>
              <div class="footer-desc">旅行社</div>
              <div class="footer-desc">景区</div>
              <div class="footer-desc">酒店民宿</div>
              <div class="footer-desc">文旅集团</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="footer-label">关于我们</div>
            <div>
              <div class="footer-desc">公司介绍</div>
              <div class="footer-desc">联系我们</div>
              <div class="footer-desc">事业合伙人招募</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="footer-ol">
              <div class="footer-ol-li">
                <div class="footer-ol-li-label">
                  <img
                    class="footer-ol-li-label-icon"
                    src="@/assets/35b21c03-a5eb-457b-a8d7-74e120c14c30.png"
                  />
                  <div>在线联系:</div>
                </div>
                <div class="footer-ol-li-vlaue">
                  <img
                    class="footer-ol-li-vlaue-icon"
                    src="@/assets/4f468585-085e-4f76-9808-6ff959346db6.png"
                  />
                  <div>QQ交谈:</div>
                </div>
              </div>
              <div class="footer-ol-li">
                <div class="footer-ol-li-label">
                  <img
                    class="footer-ol-li-label-icon"
                    src="@/assets/c2b0ffe4-b981-411b-915c-bde3e521f9d1.png"
                  />
                  <div>热线电话:</div>
                </div>
                <div class="footer-ol-li-vlaue">18081949861</div>
              </div>
              <div class="footer-ol-li">
                <div class="footer-ol-li-label">
                  <img class="footer-ol-li-label-icon" />
                  <div>邮箱:</div>
                </div>
                <div class="footer-ol-li-vlaue">yanglei@doyiter.com</div>
              </div>
              <div class="footer-ol-li">
                <div class="footer-qrbox">
                  <img class="footer-qrbox-qr" src="@/assets/knowledge_841541d2-9d67-4cca-ad62-3ea780c288ac.jpg" />
                  <div>微信公众号</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.footer {
  padding: 80px 0;
  background-color: #333333;
  line-height: 1;
  color: #ffffff;
  &-label {
    font-size: 20px;
    margin-bottom: 32px;
  }
  &-desc {
    font-size: 15px;
  }
  &-desc + &-desc {
    margin-top: 15px;
  }
  &-ol {
    font-size: 15px;
    color: #ffffff;
    &-li + &-li {
      margin-top: 15px;
    }
    &-li {
      display: flex;
      align-items: center;
      &-label {
        margin-right: 5px;
      }
      &-label,
      &-vlaue {
        display: flex;
        align-items: center;
        &-icon {
          width: 24px;
          display: block;
          margin-right: 10px;
        }
      }
    }
  }
  &-qrbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    font-size: 15px;
    color: #ffffff;
    &-qr {
      width: 116px;
      height: 116px;
      background-color: #d8d8d8;
      border: 1px solid #707070;
      margin-bottom: 10px;
    }
  }
}
</style>