import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    header: {
      isFixed: false
    }
  },
  mutations: {
    setHeader(state, val = {}) {
      state.header = {
        ...state.header,
        ...val,
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
