import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/aboutUs',
    component: () => import('@/views/aboutUs/index.vue')
  },
  {
    path: '/successful',
    component: () => import('@/views/successful/index.vue')
  },
  {
    path: '/contactUs',
    component: () => import('@/views/contactUs/index.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(){
    return { x: 0, y: 0 }
  },
  routes
})

export default router
