<template>
  <div class="copyright">
    <div class="uk-container uk-container-large">
      Copyright ©2023 成都度壹特科技有限公司 allrights reserve 备案号：<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备16020634号-2</a> 技术支持：度壹特
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.copyright {
  padding: 16px;
  text-align: center;
  background: #1c1c1c;
  color: #8d8d8d;
  a{
    color: #8d8d8d;
    &:hover{
      color: #fe5f52;
    }
  }
}
</style>