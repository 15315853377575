import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import UIkit from 'uikit'
import "uikit/dist/css/uikit.min.css";
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);
import "./style/main.less";
import "./style/ch.less";
import mixins from "./mixins/mixins";
Vue.mixin(mixins)
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
